<template>
	<div
		:ref="CAROUSEL_ITEM"
		class="w-full"
	>
		<router-link
			:to="business.url"
			class="flex w-full p-3 px-4 my-2 bg-white border rounded-md shadow-md cursor-pointer md:px-4 lg:px-4"
		>
			<div class="relative hidden my-auto mr-3 rounded md:flex">
				<WwImage
					:src="businessImageFile"
					class="thumbnail-width"
					classes="rounded-md"
					object-cover
					:alt="business.name"
					:fallback-image="DEFAULT_WW_GRAY_LOGO"
					:width="140"
					:height="140"
					:image-path="LISTING_IMAGES"
				/>
			</div>
			<div class="justify-center mx-auto md:mx-0 card-inner-height">
				<div class="grid h-full grid-cols-1">
					<div class="my-auto font-bold text-black truncate">
						{{ businessName }}
					</div>
					<div
						class="flex items-center my-auto mb-1 text-sm"
						:class="hasNoRating ? 'text-gray-500' : 'text-black'"
					>
						<img
							:src="starIcon"
							width="18"
							height="18"
							class="mr-1"
							alt="Star Rating"
						>
						<span class="font-bold">
							{{ formattedAverage }}
						</span>
						<span
							v-if="hasReviews"
							class="ml-1"
						>
							({{ business.review_count }})
						</span>
						<span class="ml-2 text-black truncate">
							<span class="mr-1 text-gray-300">
								•
							</span>
							<span v-if="!isDC">
								{{ business.state_name }},
							</span>
							{{ business.city }}
						</span>
					</div>

					<div
						:class="isMapPage ? 'text-xs' : 'text-sm'"
						class="flex items-center w-full my-auto text-gray-500 truncate"
					>
						<img
							:src="businessIcon"
							class="mr-2.5"
							width="18"
							height="18"
							alt="Business Icon"
						>
						<span class="capitalize">
							{{ business.type }}
						</span>
						<div class="pl-2">
							<span class="mr-1 text-gray-300">
								•
							</span>
							{{ businessIndication }}
						</div>
					</div>

					<div
						v-if="hasChips"
						class="mt-auto text-sm font-bold uppercase"
					>
						<span
							v-if="isOpen"
							class="bg-green-100 text-green-500 px-2 py-0.5 mr-2 rounded"
						>
							<span>
								Open
							</span>
						</span>
						<span
							v-if="hasDeals"
							class="text-yellow-500 bg-yellow-100 px-2 py-0.5 mr-2 rounded"
						>
							<span>
								{{ dealCount }} Deals
							</span>
						</span>
						<span
							v-if="hasDelivery"
							class="text-black bg-gray-200 px-2 py-0.5 rounded"
						>
							<span>
								Delivery
							</span>
						</span>
					</div>

					<div
						v-else
						class="mt-auto py-2.5"
					/>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script async>
import { BUSINESS_TYPE_ICONS } from '@/constants/business/index.js'
import CAROUSEL_ITEM from '@/constants/carousel/carousel-item.js'
import { LISTING_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import requireIcon from '@/utils/requireIcon.js'

export default {
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue')
	},
	props: {
		business: {
			type: Object,
			required: true
		},
		isMapPage: {
			type: Boolean,
			default: false
		}
	},
	emits: [ 'set-active-business' ],
	data() {
		return {
			isMounted: false,
			hover: false,
			DEFAULT_WW_GRAY_LOGO,
			CAROUSEL_ITEM,
			LISTING_IMAGES
		}
	},
	computed: {
		businessName() {
			return this.business.name || '-'
		},
		businessIndication() {
			switch (this.business?.indication) {
				case 'medical':
					return 'Medical Only'
				case 'recreational':
					return 'Recreational Only'
				default:
					return 'Medical & Recreational'
			}
		},
		businessImageFile() {
			return this.business?.image || 'default.png'
		},
		businessIcon() {
			return requireIcon(BUSINESS_TYPE_ICONS[this.business.type])
		},
		isOpen() {
			return !!this.business?.is_open
		},
		isDC() {
			return (this.business.state_name && this.business.state_name.includes('DC')) || (this.business.state_short && this.business.state_short.includes('DC'))
		},
		reviewAverage() {
			return this.business.review_average && this.business.review_average !== undefined ? (Math.round(this.business.review_average * 10) / 10).toFixed(1) : '0.0'
		},
		formattedAverage() {
			return this.reviewAverage % 1 == 0 ? parseInt(this.reviewAverage) : this.reviewAverage
		},
		hasNoRating() {
			return this.reviewAverage === 0
		},
		hasChips() {
			return this.isOpen || this.hasDeals || this.hasDelivery
		},
		hasDeals() {
			return this.business?.deals && !!this.business?.deals.length
		},
		hasDelivery() {
			return this.business?.delivery == 1
		},
		hasReviews() {
			return this.business?.review_count && this.business.review_count > 0
		},
		dealCount() {
			return this.business.deals && this.business?.deals.length ? this.business?.deals.length : 0
		},
		starIcon() {
			if (this.hasNoRating) {
				return requireIcon('star-full-black.svg')
			} else {
				return requireIcon('star-full-yellow.svg')
			}
		}
	},
	methods: {
		showMapInfoWindow() {
			this.$emit('set-active-business', this.business.listing_id)
		}
	}
}
</script>

<style lang="scss" scoped>
.card-inner-height {
	height: 6.25rem;
}
.thumbnail-width {
	min-width: 6.25rem;
	max-width: 6.25rem;
}
</style>
