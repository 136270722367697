<template>
	<div class="w-full">
		<WwCarousel
			:item-count="itemCount"
			:items-per-slide="1"
			side-scroller-class="pb-8"
			scrollbar-visibility="hide-horizontal-scrollbar"
			arrow-visibility="hidden"
			fade-visibility="hidden"
			autoplay
		>
			<template #header>
				<h3 class="w-full p-3 text-base font-bold text-center uppercase xl:px-0 flex-nowrap">
					Explore Nearby Businesses
				</h3>
			</template>
			<template #slides>
				<BusinessNearbyCard
					v-for="(business, index) in items"
					:key="`business-${index}`"
					:business="business"
					:is-map-page="isMapPage"
					class="w-full flex-shrink-0 flex px-1.5"
				/>
			</template>
		</WwCarousel>
	</div>
</template>

<script async>
import WwCarousel from '@/components/UI/WwCarousel.vue'

import BusinessNearbyCard from './BusinessNearbyCard.vue'

export default {
	components: {
		WwCarousel,
		BusinessNearbyCard
	},
	props: {
		items: {
			type: Array,
			required: true
		},
		itemCount: {
			type: Number,
			required: true
		},
		isMapPage: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			isMounted: false
		}
	},
	mounted() {
		this.isMounted = true
	}
}
</script>
